import { Label } from "@fluentui/react";
import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { ChatResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    activeCitation: string | undefined;
    citationHeight: string;
    chatResponse: ChatResponse;
}

export const AnalysisPanel = ({ chatResponse: answer, activeTab, activeCitation, citationHeight, className }: Props) => {
    return (
        <div className={`${styles.analysisPanelContent} ${className}`}>
            {activeTab === AnalysisPanelTabs.ThoughtProcessTab && (
                <div className={styles.thoughtProcess}>
                    <Label className={styles.thoughtProcessHeader}>Classification:</Label>
                    <Label className={styles.thoughtProcessParagraph}>{answer.classification}</Label>
                    {answer.answer.query_generation_prompt && <Label className={styles.thoughtProcessHeader}>Query Generation Prompt:</Label>}
                    {answer.answer.query_generation_prompt && <Label className={styles.thoughtProcessParagraph}>{answer.answer.query_generation_prompt}</Label>}
                    {answer.answer.query && <Label className={styles.thoughtProcessHeader}>Generated Query:</Label>}
                    {answer.answer.query && <Label className={styles.thoughtProcessParagraph}>{answer.answer.query}</Label>}
                    {answer.answer.query_result && <Label className={styles.thoughtProcessHeader}>Query Result:</Label>}
                    {answer.answer.query_result && <Label className={styles.thoughtProcessParagraph}>{answer.answer.query_result}</Label>}
                </div>
            )}

            {activeTab === AnalysisPanelTabs.SupportingContentTab && <SupportingContent supportingContent={answer.data_points} />}

            {activeTab === AnalysisPanelTabs.CitationTab && (
                <iframe title="Citation" src={activeCitation} width="100%" height={citationHeight} className={styles.citationIframe} />
            )}
        </div>
    );
};
