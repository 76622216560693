import { ChatRequest, ChatResponse, SearchSettings, UserProfile, ratingRequest, ratingResponse } from "./models";

export class ChatResponseError extends Error {
    public retryable: boolean;

    constructor(message: string, retryable: boolean) {
        super((message = message));
        this.message = message;
        this.retryable = retryable;
    }
}

export class ratingResponseError extends Error {
    public retryable: boolean;

    constructor(message: string, retryable: boolean) {
        super((message = message));
        this.message = message;
        this.retryable = retryable;
    }
}

export async function chatApi(options: ChatRequest): Promise<ChatResponse> {
    const response = await fetch("/chat", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id: options.userID,
            conversation_id: options.conversationID,
            dialog_id: options.dialogID,
            dialog: options.dialog,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                // exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                classification_override: options.overrides?.classificationOverride,
                vector_search: options.overrides?.vectorSearch,
                pdfDataSourceIndustry1: options.overrides?.pdfDataSourceIndustry1,
                pdfDataSourceSurface1: options.overrides?.pdfDataSourceSurface1,
                pdfDataSourceMedical1: options.overrides?.pdfDataSourceMedical1,
                pdfWeightIndustry1: options.overrides?.pdfWeightIndustry1,
                pdfWeightSurface1: options.overrides?.pdfWeightSurface1,
                pdfWeightMedical1: options.overrides?.pdfWeightMedical1
            }
        })
    });

    const parsedResponse: ChatResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw new ChatResponseError(parsedResponse.error ?? "An unknown error occurred.", parsedResponse.show_retry ?? false);
    }

    return parsedResponse;
}

export async function getAllUsers(): Promise<UserProfile[]> {
    const token = localStorage.getItem("authToken"); // Retrieve token from local storage
    const response = await fetch("/user-profiles", {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}` // Include the token here
        }
    });

    if (response.status > 299 || !response.ok) {
        throw Error("Received error response when fetching user profiles.");
    }

    const userProfiles: UserProfile[] = await response.json();
    return userProfiles;
}

export async function clearChatSession(userID: string, conversationID: string): Promise<void> {
    const response = await fetch(`/chat-sessions/${userID}/${conversationID}`, {
        method: "DELETE"
    });

    if (response.status > 299 || !response.ok) {
        throw Error(`Received error response when attemping to clear chat session: ${await response.text()}.`);
    }
}

export async function getSearchSettings(): Promise<SearchSettings> {
    const response = await fetch("/search-settings", {
        method: "GET"
    });

    if (response.status > 299 || !response.ok) {
        throw Error("Received error response when fetching search settings.");
    }

    const searchSettings: SearchSettings = await response.json();
    return searchSettings;
}

export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;
}

export async function rating(options: ratingRequest): Promise<ratingResponse> {
    const response = await fetch("/rating", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id: options.user_id,
            conversation_id: options.conversation_id,
            index_no: options.index_no,
            rating: options.rating,
            feedback: options.feedback
        })
    });

    const parsedresponse: ratingResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw new ratingResponseError(parsedresponse.error ?? "An unknown error occured.", parsedresponse.show_retry ?? false);
    }

    return parsedresponse;
}
