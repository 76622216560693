const NoPage = () => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
            fontSize: "24px",
            color: "#666",
            textAlign: "center"
        }}>
            <h1 style={{ fontSize: "96px", margin: "0" }}>404</h1>
            <p>Page Not Found</p>
        </div>
    );
};

export default NoPage;
