import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import styles from "./SignIn.module.css";
import googleLogo from "../../assets/google-logo.png";
import facebookLogo from "../../assets/facebook-logo.png";
import linkedinLogo from "../../assets/linkedin-logo.png";

// const apiBaseUrl = process.env.REACT_APP_API_URL;

const SignIn: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    // Handle LinkedIn login
    const handleLinkedInLogin = () => {
        window.location.href = "https://monolithai.me/api/auth/linkedin-login";
    };

    const handleEmailLogin = async () => {
        if (!email || !password) {
            setErrorMessage("Please enter both email and password.");
            return;
        }
        if (!validateEmail(email)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        try {
            // Send login request to the backend
            const response = await axios.post(`https://monolithai.me/api/signin`, { email, password });
            // const response = await axios.post(`http://localhost:5000/api/signin`, { email, password });

            // Check if token is received
            if (response.data?.token) {
                const token = response.data.token;

                // Store the token in localStorage
                localStorage.setItem("authToken", token);

                // Attach token to axios defaults for future requests
                axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

                // Navigate to the landing page on successful login
                navigate("/landing");
            } else {
                // Handle case where token is not received
                setErrorMessage(response.data?.error || "Login failed. Please try again.");
            }
        } catch (error: any) {
            console.error("Error during login:", error); // Log the full error

            // Check for a response error
            if (error.response) {
                console.log("Response error data:", error.response.data); // Log response error data
                switch (error.response.status) {
                    case 400:
                        setErrorMessage("Please provide email and password.");
                        break;
                    case 401:
                        setErrorMessage("Invalid email or password.");
                        break;
                    case 402:
                        setErrorMessage("Account is not active.");
                        break;
                    case 403:
                        setErrorMessage("Access restricted to paid users. Please upgrade to continue.");
                        break;
                    default:
                        setErrorMessage("An error occurred during login. Please try again.");
                }
            } else if (error.request) {
                // Check if request was made but no response received
                console.log("Request error:", error.request); // Log request error
                setErrorMessage("No response received from server. Please check your network and try again.");
            } else {
                // Handle other errors, such as configuration issues
                console.log("General error:", error.message); // Log general error
                setErrorMessage("An unexpected error occurred. Please try again.");
            }
        }
    };

    // Validate email format
    const validateEmail = (email: string) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    return (
        <div className={styles["sign-in-page"]}>
            <div className={styles["sign-in-container"]}>
                <div className={styles["sign-in-header"]}>
                    <h2>Welcome to</h2>
                    <h1>Floating Sage</h1>
                    <p>Please sign in to continue</p>
                </div>

                <div className={styles["social-login-buttons"]}>
                    <img
                        src={linkedinLogo}
                        alt="LinkedIn Logo"
                        className={`${styles["social-logo"]} ${styles["social-button"]}`}
                        onClick={handleLinkedInLogin}
                    />
                    <img
                        src={googleLogo}
                        alt="Google Logo"
                        className={`${styles["social-logo"]} ${styles["social-button"]}`}
                        onClick={() => {
                            window.location.href = "https://monolithai.me/api/auth/google-login";
                        }}
                    />
                    <img
                        src={facebookLogo}
                        alt="Facebook Logo"
                        className={`${styles["social-logo"]} ${styles["social-button"]}`}
                        onClick={() => {
                            window.location.href = "https://monolithai.me/api/auth/facebook-login";
                        }}
                    />
                </div>

                <div className={styles["divider"]}>
                    <hr />
                    <span>or</span>
                    <hr />
                </div>

                {/* Email login form */}
                <div className={styles["email-login"]}>
                    <input
                        type="email"
                        className={`${styles["input-field"]} ${styles["stylish-input"]}`}
                        placeholder="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        className={`${styles["input-field"]} ${styles["stylish-input"]}`}
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <button className={`${styles["email-login-button"]} ${styles["stylish-button"]}`} onClick={handleEmailLogin}>
                        Login
                    </button>
                </div>

                {/* Display error message if login fails */}
                {errorMessage && <p className={styles["error-message"]}>{errorMessage}</p>}

                {/* New Register Link */}
                <p className={styles["register-text"]}>
                    Don't have an account?{" "}
                    <Link to="/register" className={styles["register-link"]}>
                        Register here
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default SignIn;
