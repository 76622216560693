// Importing React and necessary components
import React from "react";
import styles from "./LandingPage.module.css";
import { useNavigate } from "react-router-dom";
import { LiaGripLinesVerticalSolid } from "react-icons/lia";

// Dummy chatbot data
const chatbotData = [
    { id: 1, name: "Product Documentation Chatbot" },
    { id: 2, name: "Marketing Chatbot" },
    { id: 3, name: "Database Chatbot" }
];

const LandingPage = () => {
    const navigate = useNavigate();

    const navigateTo = (route: string) => {
        navigate(route);
    };

    return (
        <div className={styles.landingPage}>
            <h1 className={styles.title}>Welcome to Your Chatbot Dashboard</h1>
            <div className={styles.chatbotsContainer}>
                {chatbotData.map(chatbot => (
                    <div key={chatbot.id} className={styles.chatbotRow}>
                        <span className={styles.chatbotName}>{chatbot.name}</span>
                        <div className={styles.linkContainer}>
                            <a href="#" onClick={() => navigateTo(`/lander`)} className={styles.link}>
                                Chat Bot
                            </a>
                            <LiaGripLinesVerticalSolid className={styles.dividerIcon} />
                            <a href="#" onClick={() => navigateTo(`/data-upload`)} className={styles.link}>
                                Upload Data
                            </a>
                            <LiaGripLinesVerticalSolid className={styles.dividerIcon} />
                            <a href="#" onClick={() => navigateTo(`/chatbot/${chatbot.id}/usage-report`)} className={styles.link}>
                                Usage Report
                            </a>
                            <LiaGripLinesVerticalSolid className={styles.dividerIcon} />
                            <a href="#" onClick={() => navigateTo(`/chatbot/${chatbot.id}/support-tickets`)} className={styles.link}>
                                Support Tickets
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LandingPage;
