import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./RegistrationPage.module.css";
import axios from "axios";

// const apiBaseUrl = process.env.REACT_APP_API_URL;

const RegistrationPage: React.FC = () => {
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: "",
        confirmPassword: ""
    });
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    //   e.preventDefault();
    //   setLoadingMessage('Please wait... Checking registration status.');

    //   try {
    //     // Check if user is already registered
    //     const checkResponse = await axios.post('/api/register/check', {
    //       email: formData.email,
    //     });
    //     if (checkResponse.data.isRegistered) {
    //       setLoadingMessage('User is already registered.');
    //       return;
    //     }

    //     // If not registered, proceed to register the user
    //     setLoadingMessage('User registration in process... Please wait.');
    //     await axios.post('/api/register', {
    //       username: formData.username,
    //       email: formData.email,
    //       password: formData.password,
    //     });
    //     setLoadingMessage('Successfully registered user, welcome to Floating Sage!');
    //   } catch (error) {
    //     setLoadingMessage('An error occurred. Please try again later.');
    //   }
    // };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadingMessage("Please wait... Checking registration status.");

        try {
            // Check if user is already registered
            const checkResponse = await axios.post(`https://monolithai.me/api/register/check`, {
                email: formData.email
            });
            if (checkResponse.data.isRegistered) {
                setLoadingMessage("User is already registered.");
                return;
            }

            // If not registered, proceed to register the user
            setLoadingMessage("User registration in process... Please wait.");
            await axios.post(`https://monolithai.me/api/register`, {
                username: formData.username,
                email: formData.email,
                password: formData.password
            });
            setLoadingMessage("Successfully registered user, welcome to Floating Sage!");
        } catch (error) {
            setLoadingMessage("An error occurred. Please try again later.");
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.glassCard}>
                <h2 className={styles.heading}>Create Your Account</h2>
                <p className={styles.subHeading}>Start your journey with us</p>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.inputGroup}>
                        <label htmlFor="username" className={styles.label}>
                            Username
                        </label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                placeholder="Enter your username"
                                required
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="email" className={styles.label}>
                            Email
                        </label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                                required
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="password" className={styles.label}>
                            Password
                        </label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Enter your password"
                                required
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="confirmPassword" className={styles.label}>
                            Confirm Password
                        </label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                placeholder="Confirm your password"
                                required
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <button type="submit" className={styles.submitButton}>
                        Register
                    </button>
                </form>
                {loadingMessage && (
                    <div className={styles.loadingMessage}>
                        <p>{loadingMessage}</p>
                    </div>
                )}
                <p className={styles.loginText}>
                    Already have an account?{" "}
                    <Link to="/signin" className={styles.loginLink}>
                        Login here
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default RegistrationPage;
