import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import SignIn from "./pages/signin/SignIn"; // SignIn page at root "/"
import RegistrationPage from "./pages/registrationpage/RegistrationPage";

import Layout from "./pages/layout/Layout"; // Main app layout for /lander
import PageContainer from "./pages/PageContainer"; // Page container that holds the app logic
import NoPage from "./pages/NoPage"; // Fallback for non-existing pages
import LandingPage from "./pages/LandingPage/LandingPage";
import DataUpload from "./pages/DataUpload/DataUpload";

initializeIcons();

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                {/* Root route for Sign-In page */}
                <Route path="/" element={<SignIn />} />

                {/* Root route for Sign-In page */}
                <Route path="/register" element={<RegistrationPage />} />

                <Route path="/landing" element={<LandingPage />} />

                <Route path="/data-upload" element={<DataUpload />} />

                {/* /lander route for the main application layout */}
                <Route path="/lander" element={<Layout />}>
                    <Route index element={<PageContainer />} />
                    {/* Load PageContainer under Layout */}
                </Route>

                {/* Fallback route in case of an unknown route */}
                <Route path="*" element={<NoPage />} />
            </Routes>
        </BrowserRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
