import { useMemo, useState, useEffect } from "react";
import { Stack, IconButton, PrimaryButton, Label, Modal } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { ChatResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { ErrorCircle20Regular } from "@fluentui/react-icons";
import { AnalysisPanel } from "../AnalysisPanel/AnalysisPanel";
import { AnalysisPanelTabs } from "../AnalysisPanel/AnalysisPanelTabs";

interface Props {
    chatResponse: ChatResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    sendLikeRating: () => void;
    sendDislikeRating: () => void;
    undoLike: () => void;
    undoDislike: () => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    onRetryClicked?: () => void;
    retryable: boolean;
    isLiked?: boolean;
    isDisliked?: boolean;
}

export const Answer = ({
    chatResponse,
    isSelected,
    sendLikeRating,
    sendDislikeRating,
    undoLike,
    undoDislike,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    onRetryClicked,
    retryable,
    isLiked,
    isDisliked
}: Props) => {
    const parsedAnswer = useMemo(() => parseAnswerToHtml(chatResponse, onCitationClicked), [chatResponse]);
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    const [isSmallScreen, setIsSmallScreen] = useState({
        disableThoughtProcess: false,
        disableSupportingContent: false
    });

    const [isThoughtProcessModalOpen, setIsThoughtProcessModalOpen] = useState(false);
    const [isSupportingContentModalOpen, setIsSupportingContentModalOpen] = useState(false);

    const checkScreenSize = () => {
        setIsSmallScreen({
            disableThoughtProcess: window.innerWidth < 800,
            disableSupportingContent: window.innerWidth < 1000
        });
    };

    useEffect(() => {
        checkScreenSize();
        window.addEventListener("resize", checkScreenSize);

        return () => window.removeEventListener("resize", checkScreenSize);
    }, []);

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between" className={styles.headerIcons}>
                    <AnswerIcon />
                    <div>
                        <IconButton
                            style={{ color: "white" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => setIsThoughtProcessModalOpen(true)}
                            disabled={
                                isSmallScreen.disableThoughtProcess ||
                                (!chatResponse.classification &&
                                    !chatResponse.answer.query &&
                                    !chatResponse.answer.query_generation_prompt &&
                                    !chatResponse.answer.query_result)
                            }
                        />

                        <IconButton
                            style={{ color: "white" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => setIsSupportingContentModalOpen(true)}
                            disabled={isSmallScreen.disableSupportingContent || !chatResponse.data_points.length}
                        />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                {retryable && onRetryClicked && (
                    <div className={styles.retryContainer}>
                        <ErrorCircle20Regular />
                        <Label className={styles.retryText}>
                            Looks like this search ran into an issue. Would you like me to try again with an expanded scope?
                        </Label>
                        <PrimaryButton className={styles.retryButton} onClick={onRetryClicked} text="Retry" />
                    </div>
                )}
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            const title_x = x.replaceAll("%20", " ");
                            return (
                                <a key={i} className={styles.citation} title={title_x} href={x} target="_blank">
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {parsedAnswer.followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <div>
                        <IconButton
                            className={`${styles.iconButton} ${isLiked && styles.iconButtonFilled}`}
                            iconProps={{ iconName: isLiked ? "LikeSolid" : "Like" }}
                            title="Like"
                            ariaLabel="Like"
                            onClick={isLiked ? () => undoLike() : () => sendLikeRating()}
                            disabled={
                                !chatResponse.classification &&
                                !chatResponse.answer.query &&
                                !chatResponse.answer.query_generation_prompt &&
                                !chatResponse.answer.query_result
                            }
                        />

                        <IconButton
                            className={`${styles.iconButton} ${isDisliked && styles.iconButtonFilled}`}
                            iconProps={{ iconName: isDisliked ? "DislikeSolid" : "Dislike" }}
                            title="Dislike"
                            ariaLabel="Dislike"
                            onClick={isDisliked ? () => undoDislike() : () => sendDislikeRating()}
                            disabled={
                                !chatResponse.classification &&
                                !chatResponse.answer.query &&
                                !chatResponse.answer.query_generation_prompt &&
                                !chatResponse.answer.query_result
                            }
                        />
                    </div>
                </Stack>
            </Stack.Item>

            {/* Modal for Thought Process */}
            <Modal
                isOpen={isThoughtProcessModalOpen}
                onDismiss={() => setIsThoughtProcessModalOpen(false)}
                isBlocking={false}
                styles={{ main: { maxWidth: "80vw", maxHeight: "80vh", overflowY: "auto" } }}
            >
                <div className={styles.modalHeader}>
                    <h2>Thought Process</h2>
                    <IconButton iconProps={{ iconName: "Cancel" }} ariaLabel="Close popup" onClick={() => setIsThoughtProcessModalOpen(false)} />
                </div>
                <div className={styles.modalContent}>
                    <AnalysisPanel
                        className={styles.analysisPanelModal}
                        activeTab={AnalysisPanelTabs.ThoughtProcessTab}
                        // onActiveTabChanged={() => {}}
                        activeCitation={undefined}
                        citationHeight="auto"
                        chatResponse={chatResponse}
                    />
                </div>
            </Modal>

            {/* Modal for Supporting Content */}
            <Modal
                isOpen={isSupportingContentModalOpen}
                onDismiss={() => setIsSupportingContentModalOpen(false)}
                isBlocking={false}
                styles={{ main: { maxWidth: "80vw", maxHeight: "80vh", overflowY: "auto" } }}
            >
                <div className={styles.modalHeader}>
                    <h2>Supporting Content</h2>
                    <IconButton iconProps={{ iconName: "Cancel" }} ariaLabel="Close popup" onClick={() => setIsSupportingContentModalOpen(false)} />
                </div>
                <div className={styles.modalContent}>
                    <AnalysisPanel
                        className={styles.analysisPanelModal}
                        activeTab={AnalysisPanelTabs.SupportingContentTab}
                        // onActiveTabChanged={() => {}}
                        activeCitation={undefined}
                        citationHeight="auto"
                        chatResponse={chatResponse}
                    />
                </div>
            </Modal>
        </Stack>
    );
};
