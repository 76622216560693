import { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import github from "../../assets/github.svg";
import styles from "./Layout.module.css";
import { Modal, IconButton } from "@fluentui/react"; // Import Fluent UI components
import monolith from "../../assets/FloatingSage-Orange-logo2-only-name-transparent.png";
import monolithLogo from "../../assets/FloatingSage-Orange-icon-transparent.png";

// Layout component to handle the general structure of the application
const Layout = () => {
    const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false); // State to manage disclaimer modal

    // State to track screen orientation
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > window.innerHeight);

    // Effect to handle screen orientation changes
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > window.innerHeight);
            console.log("Screen resized. Wide screen: ", window.innerWidth > window.innerHeight); // Log screen resize events
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Toggle function for disclaimer modal
    const toggleDisclaimer = () => {
        setIsDisclaimerOpen(!isDisclaimerOpen);
        console.log("Disclaimer modal state changed: ", !isDisclaimerOpen); // Log disclaimer modal toggle
    };

    return (
        <div className={`${styles.layout} ${isWideScreen ? styles.verticalFit : styles.horizontalFit}`}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link reloadDocument to="/" className={styles.headerTitleContainer}>
                        {/* <h3 className={styles.headerTitle}>Floating Sage</h3> */}
                        <img src={monolithLogo} alt="Floating Sage logo" className={styles.headerLogo} />
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li className={styles.headerNavItem}>
                                <a
                                    className={styles.githubLink}
                                    href="https://monolithai.me/"
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                    title="Link to MonolithAI.me"
                                    aria-label="Link to MonolithAI.me"
                                >
                                    <img
                                        src={monolith}
                                        alt="Floating Sage logo"
                                        aria-label="Link to MonolithAI.me"
                                        width="100px"
                                        height="24px"
                                        className={styles.githubLogo}
                                    />
                                    <span className={styles.githubText}>Contact Us</span>
                                </a>
                            </li>
                            {/* Disclaimer Icon */}
                            <li>
                                <IconButton
                                    iconProps={{ iconName: "Info" }}
                                    title="Disclaimer"
                                    ariaLabel="Disclaimer"
                                    className={styles.disclaimerButton}
                                    onClick={toggleDisclaimer}
                                />
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>

            {/* Main content that adjusts for the fixed header */}
            <main className={styles.mainContent}>
                <Outlet />
            </main>

            {/* Disclaimer Modal */}
            <Modal isOpen={isDisclaimerOpen} onDismiss={toggleDisclaimer} isBlocking={false} containerClassName={styles.modalContainer}>
                <div className={styles.modalHeader}>
                    <h2>Disclaimer</h2>
                    <IconButton iconProps={{ iconName: "Cancel" }} onClick={toggleDisclaimer} />
                </div>
                <div className={styles.modalContent}>
                    <b>DISCLAIMER:</b>
                    <br />
                    1. AI can make mistakes. Check important info <br />
                    2. All user inputs and bot responses are logged <a href="https://monolithai.me/Acknowledgement">Link to Acknowledgement.</a>
                </div>
            </Modal>
        </div>
    );
};

export default Layout;
